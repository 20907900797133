.App {
  font-family: "Roboto", sans-serif;
  color: #6a747e;
}

.App__logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App__logo {
    animation: App__logo-spin infinite 20s linear;
  }
}

.PaymentProcessingOverlay,
.ApplePayOverlay {
  position: fixed;
  bottom: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in;
}

.PaymentProcessingOverlay {
  background: #f2f7ff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ApplePayOverlay {
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url("./assets/images/apple_pay_overlay.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.App__header {
  padding: 50px 0 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: rgb(33, 116, 255);
  background: linear-gradient(
    180deg,
    rgba(33, 116, 255, 1) 0%,
    rgba(116, 199, 200, 1) 100%
  );
}

.Receiver__card {
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.Receiver__card__content {
  margin-left: 17px;
}

.Receiver__card__content,
.Receiver__card__photo {
  transition: all 0.3s ease-in;
}

.LocationWrap {
  margin-left: 120px;
  margin-bottom: 32px;
  opacity: 0.5;
}

.card__content__name {
  font-size: 20px;
  font-weight: 500;
}

.Step {
  position: relative;
}

.Step__header {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.Step__arrow {
  padding: 25px 25px 25px 0px;
}

.IconMini {
  margin-right: 10px;
  width: 13px;
  height: 13px;
}

.Rating__stars,
.Rating__stars * {
  transition: all 0.3s ease-in;
}

.Rating__stars .Star {
  margin-right: 5px;
}

.Rating__stars {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.Rating__stars.hide {
  display: none;
}

.Rating__stars.small {
  transform: translate(0px, -85px);
  position: absolute;
  top: 66px;
  width: 100%;
}

.Rating__stars.medium {
  transform: translate(0px, -110px);
  position: absolute;
  top: 66px;
  width: 100%;
}

.Rating__stars.medium .Star {
  width: 35px;
  height: 35px;
}

.Rating__stars.small .Star {
  width: 25px;
  height: 25px;
  opacity: 0.4;
}

.TipAmount {
  width: 85%;
  margin: 0 auto;
}

.TipAmount__button {
  border-radius: 100px;
  font-weight: 400;
  font-size: 2.2rem;
  text-align: center;
  padding: 10px 20px;
  color: #9ca3aa;
  box-shadow: 0px 0px 8px #00000029;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.15s ease-in;
  background: #fff;
}

.TipAmount__button--active {
  background: #6dc9c8;
  color: #fff;
}

.TipAmount__standard {
  display: flex;
  justify-content: space-between;
}

.TipAmount__standard .TipAmount__button {
  width: 15%;
}

.TipAmount__sixStar {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.TipAmount__sixStar .TipAmount__button {
}

.TipAmount__sixStar .TipAmount__note {
  flex: 1;
}

.TipAmount__sixStar .TipAmount__button .Star {
  margin-left: 5px;
  width: 37px;
  height: 37px;
}
.TipAmount__custom {
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 25px;
}

.TipAmount__custom__button,
.StepComment__CustomButton {
  color: #6dc9c8;
  font-weight: 500;
}

.Tip__skip__button {
  margin-top: 25px;
  text-align: center;
  color: #d2d5d8;
  font-weight: 500;
}

.card {
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 7px;
  padding: 12px 24px;
  background: #fff;
}

.StepSupport__Chat__InputWrap {
  display: flex;
  align-content: center;
  margin-top: 20px;
}

.StepSupport__Chat__InputWrap__Input {
  height: 20px;
  border-radius: 30px;
  flex: 1;
  margin-right: 10px;
}

.StepSupport__Chat__InputWrap__SendButton {
  border-radius: 25px;
  display: flex;
  background: #6dc9c8;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 35px;
  padding: 0px 20px;
  color: white;
  box-shadow: 0px 0px 8px #00000029;
}

.StepSupport__Chat__ChatBubble,
.StepSupport__Chat__InputWrap__Input {
  border: 1px solid #ddd;
  padding: 6px 12px;
}

.StepSupport__Chat__ChatBubble {
  margin-top: 30px;
  border-radius: 25px;
  background: #fff;
}

.card--disabled * {
  opacity: 0.4;
}

.photo {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  box-shadow: 0px 0px 4px #00000040;
}

.CTA,
.StepComment__Button {
  border-radius: 25px;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 1.3rem;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0px 30px;
  transition: all 0.15s ease-in;
}

.CTA {
  background: #6dc9c8;
  justify-content: center;
  text-transform: uppercase;
  color: white;
}

.StepComment__Button svg {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}

.StepComment__Button {
  background: #f0f2f5;
  justify-content: start;
  color: #6a747e;
  margin-bottom: 20px;
}

.StepComment__Button--active {
  background: #6dc9c8;
  color: white;
}

.StepComment__CustomButton {
  text-align: center;
}

.StepComment {
  width: 85%;
  margin: 0 auto;
}

.CTA--disabled {
  opacity: 0.3;
}

.PaymentButton {
  border-radius: 18px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 36px;
  box-shadow: 0px 0px 8px #00000029;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.StepSupport {
}

.StepSupport__SupportMemberCard {
  display: flex;
  align-items: center;
  position: relative;
}

.StepSupport__SupportMemberCard__content {
  flex: 1;
}

.StepSupport__SupportMemberCard .online {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #00fe27;
  position: absolute;
  right: 28px;
  bottom: 18px;
  box-shadow: 0px 0px 4px #00000029;
}

.Receiver__photo__single {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px #00000029;
  display: block;
  margin: 0 auto;
  transition: all 0.3s ease-in;
}

.Receiver__photo__single--small {
  width: 130px;
  height: 130px;
}

.headline {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.StepSuccess {
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StepSuccess img {
  margin-top: 30px;
}

.AppleStoreButton {
  width: 200px;
  margin-top: 10px;
}

.GooglePlayButton {
  width: 227px;
  margin-top: 10px;
}

.StepAds {
  padding-top: 60px;
  text-align: center;
  max-width: 335px;
  margin: 0 auto;
}

.StepAds__marketingText {
  padding: 0px 20px;
}

.StepAds__marketingText li {
  text-align: left;
}

.Content {
  margin: -40px 0 26px;
  padding: 0px 20px;
}

.App-link {
  color: #61dafb;
}
